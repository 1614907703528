<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          {{ $t("Addtransfer") }}
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <!-- -->
        <div class="row text-sm-start d-flex justify-content-center">
          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label">{{
              $t("documentnumber")
            }}</label>
            <input disabled class="form-control mb-4" />
          </div>
          <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label">{{
              $t("documentdate")
            }}</label>
            <input v-model="date" disabled class="form-control mb-4" />
          </div>
        </div>

        <div class="row text-sm-start d-flex justify-content-center mb-4">
          <!-- <div class="col-sm-5">
            <label for="selectMainProduct" class="form-label">{{ $t("transferfrom") }}</label>
            <select class="form-select" v-model="form.fromWarehouseId">
              <option v-for="item in warehouses" :key="item" :value="item.id">
                {{ item.warehouse_code }} | {{ item.warehouse_short_name }} |
                {{ item.warehouse_full_name }}
              </option>
            </select>
          </div> -->

          <div class="col-sm-10">
            <label for="selectMainProduct" class="form-label required">{{
              $t("transfertowarehouse")
            }}</label>
            <select class="form-select" v-model="form.toWarehouseId">
              <option v-for="item in warehouses" :key="item" :value="item.id">
                {{ item.warehouse_code }} | {{ item.warehouse_short_name }} |
                {{ item.warehouse_full_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row text-sm-start d-flex justify-content-center mb-4">
          <div class="col-sm-10">
            <label for="selectMainProduct" class="form-label">{{
              $t("annotation")
            }}</label>
            <input v-model="form.remark" class="form-control mb-4" />
          </div>
        </div>

        <div class="row text-end">
          <div class="col-sm-12">
            <a
              href="https://chomtana-erp-demo.spoon.in.th/public_template/import_stock_warehouse.xlsx"
              class="me-2"
              style="float: left"
            >
              <i class="bi bi-download"></i> Download template</a
            >
            <button
              type="button"
              class="btn btn-sm btn-light btn-success me-2"
              style="font-size: 14px; font-weight: bold"
              @click="toggleAttachFile()"
            >
              <input
                v-show="false"
                type="file"
                id="inputexcel"
                accept=".xlsx"
                @change="onFileChange($event)"
              />
              + Import Excel
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light btn-success"
              style="font-size: 14px; font-weight: bold"
              @click="openDialogSearchProcuct()"
            >
              <!-- @click="addItem()" -->
              {{ $t("addon") }}
            </button>
            <!-- <AddItem
              :dialogAddItem="dialogAddItem"
              :warehouseId="form.toWarehouseId"
              @addProduct="addProduct"
              @closeDialogAddItem="closeDialogAddItem"
            /> -->
            <DialogSearchProduct
              :dialogSearchProduct="dialogSearchProduct"
              @closeDialogSearchProduct="closeDialogSearchProduct"
              @submitSearchProduct="submitSearchProduct"
            />
          </div>
        </div>

        <div class="table-responsive" style="padding-top: 3px">
          <a-table :dataSource="dataItemsShow" :columns="columns">
            <template #bodyCell="{ column, index, record }">
              <template v-if="column.dataIndex === 'no'">
                {{ record.no }}
              </template>
              <template v-if="column.dataIndex === 'stockAmt'">
                <input
                  type="number"
                  class="form-control"
                  v-model="record.stockAmt"
                />
              </template>
              <template v-if="column.dataIndex === 'unit'">
                <select class="form-select" v-model="record.productUnitId">
                  <option v-for="item in units" :key="item" :value="item.id">
                    {{ item.code }} | {{ item.name }}
                  </option>
                </select>
              </template>
              <template v-if="column.dataIndex === 'action'">
                <button
                  @mouseenter="hoverBBtn"
                  type="button"
                  class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                  @click="goToDelete(record, index)"
                >
                  <i class="bi bi-trash3-fill"></i>
                </button>
              </template>
            </template>
          </a-table>
          <!-- <table
            class="
              table table-hover table-rounded table-striped
              border
              gy-7
              gs-7
            "
          >
            <thead>
              <tr
                style="width: 100%"
                class="
                  fw-bold
                  fs-5
                  text-gray-800
                  border-bottom-2 border-gray-200
                  mw-200px
                "
              >
                <th>#</th>
                <th>{{ $t("itemcode") }}</th>
                <th>{{ $t("itemname") }}</th>
                <th>{{ $t("amount") }}</th>
                <th>หน่วย</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="dataItemsShow.length < 1">
                <td class="text-center" colspan="6">ไม่มีข้อมูล</td>
              </tr>
              <tr v-for="(item, index) in dataItemsShow" :key="index">
                <td></td>
                <td>{{ item.code }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.stockAmt }}</td>
                <td>{{ item.unit }}</td>
              </tr>
            </tbody>
          </table> -->
        </div>
        <div v-if="notFoundProduct.length > 0" class="pt-10">
          <h3 style="color: red">รายการนำเข้าสินค้าที่ไม่ถูกต้อง</h3>
          <a-table :dataSource="notFoundProduct" :columns="columnsNotFound">
            <template #bodyCell="{ column, index, record }">
              <template v-if="column.dataIndex === 'no'">
                {{ record.no }}
              </template>
            </template>
          </a-table>
        </div>

        <!-- <div class="row d-flex justify-content-center mt-4">
          <div class="col-sm-8">
            <label for="selectMainProduct" class="form-label">หมายเหตุ</label>
            <input class="form-control mb-4" />
            <label for="selectMainProduct" class="form-label"
              >โอนเข้าคลัง</label
            >
            <input class="form-control mb-4" />
          </div>

          <div class="col-sm-4">
            <label for="selectMainProduct" class="form-label"
              >มูลค่ารวม(ก่อนVAT)</label
            >
            <input disabled class="form-control mb-4" />

            <label for="selectMainProduct" class="form-label">ส่วนลดรวม</label>
            <input disabled class="form-control mb-4" />

            <label for="selectMainProduct" class="form-label">VAT</label>
            <input disabled class="form-control mb-4" />

            <label for="selectMainProduct" class="form-label"
              >มูลค่าสุทธิ</label
            >
            <input disabled class="form-control" />
          </div>
        </div> -->
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-light btn-primary me-2"
            style="font-size: 14px; font-weight: bold"
            @click="submittransaction()"
          >
            <!-- @click="submit()" -->
            {{ $t("save") }}
          </button>
          <button
            type="button"
            class="btn btn-sm btn-light"
            style="font-size: 14px; font-weight: bold"
            @click="cancel"
          >
            {{ $t("cancel") }}
          </button>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import AddItem from "./components/dialogAddItem.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";
import transferApi from "@/api/transfer/";
import readXlsxFile from "read-excel-file";
import DialogSearchProduct from "./components/dialogSearchProduct.vue";
import moment from "moment";

// import { filetemplate } from "@/templates/import_stock_warehouse.xlsx";

export default {
  components: {
    AddItem,
    DialogSearchProduct,
    // filetemplate,
  },
  setup() {
    document.title = "CHOMTHANA | สร้างใบโอนสินค้า";
  },
  data: () => ({
    searchProduct: "",
    isSubmit: false,
    isValidRetailPrice: false,
    dialogAddItem: false,
    form: {
      // fromWarehouseId: 0,
      toWarehouseId: null,
      remark: "",
    },
    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
    warehouses: [],
    dataItemsShow: [],
    notFoundProduct: [],
    units: [],
    columns: [
      { title: "no", dataIndex: "no" },
      { title: "code", dataIndex: "code" },
      { title: "name", dataIndex: "name" },
      { title: "stockAmt", dataIndex: "stockAmt", width: 150 },
      { title: "unit", dataIndex: "unit", width: 200 },
      { title: "action", dataIndex: "action", width: 150 },
    ],
    columnsNotFound: [
      { title: "no", dataIndex: "no" },
      { title: "code", dataIndex: "code" },
      // { title: "stockAmt", dataIndex: "stockAmt" },
      // { title: "unit", dataIndex: "unit" },
    ],
    dialogSearchProduct: false,
    date: moment(new Date()).format("DD/MM/YYYY | HH:mm น."),
  }),

  created() {
    this.getAllWarehouse();
    this.getAllUnit();
  },
  methods: {
    async getAllUnit() {
      let companyId = parseInt(localStorage.getItem("companyId"));
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.unit.getAll(companyId);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.units = responseData.data;

        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    openDialogSearchProcuct() {
      this.dialogSearchProduct = true;
    },
    closeDialogSearchProduct() {
      this.dialogSearchProduct = false;
    },
    downloadtemplate(url) {
      window.location.href = url;
    },
    toggleAttachFile() {
      document.getElementById("inputexcel").click();
    },
    async onFileChange(event) {
      this.notFoundProduct = [];
      let responseData = [];
      let responseUnit = [];
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      console.log("xlsxfile", xlsxfile);
      readXlsxFile(xlsxfile).then(async (rows) => {
        // console.log("rows:", rows);
        let index = 0;
        for (const excelData of rows) {
          if (index != 0) {
            let data = {
              search: excelData[0],
              companyId: localStorage.getItem("companyId"),
            };
            responseData = await whApi.product.search(data);
            responseUnit = await whApi.unit.getUnit(
              excelData[2],
              localStorage.getItem("companyId")
            );
            if (responseData.data.length > 0) {
              this.dataItemsShow.push({
                id: responseData.data[0].id,
                code: responseData.data[0].code,
                name: responseData.data[0].name,
                stockAmt: excelData[1],
                productUnitId: responseUnit.data[0].id,
                // productUnitId: this.units.filter((element) => {
                //   if (responseUnit.data[0].id == element.id) {
                //     return element.id;
                //   }
                // }),
              });
              this.dataItemsShow.forEach((element, index) => {
                element.no = index + 1;
              });
            } else {
              this.notFoundProduct.push({
                no: rows + 1,
                code: excelData[0],
              });
              if (this.notFoundProduct.length > 0) {
                Swal.fire({
                  icon: "error",
                  title: "เกิดข้อผิดพลาด",
                  text: "พบรายการนำเข้าสินค้าไม่ถูกต้อง",
                });
              }
              this.notFoundProduct.forEach((element, index) => {
                element.no = index + 1;
              });
            }
          }
          index++;
        }
      });
    },
    filterProductUnit(productUnitId) {
      this.units.forEach((element) => {
        if (productUnitId == element.id) {
          return element.id;
        }
      });
    },

    submitSearchProduct(item) {
      console.log("addProduct", item);
      this.dataItemsShow.push(item);
    },
    async getAllWarehouse() {
      let responseData = [];
      try {
        responseData = await whApi.warehouse.getAll(
          localStorage.getItem("branchId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.warehouses = responseData.data;
      }
    },
    async submit() {},
    async submittransaction() {
      Swal.fire({
        icon: "question",
        title: `${this.$t("want2submit")} ${this.$t("ornot")} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (this.form.toWarehouseId) {
            if (this.dataItemsShow.length > 0) {
              this.dataItemsShow.forEach((element) => {
                if (!element.productUnitId) {
                  Swal.fire({
                    icon: "error",
                    title: "กรุณาเลือกหน่วยสินค้า",
                    showConfirmButton: true,
                  });
                  this.isValid = false;
                  if (!element.stockAmt) {
                    Swal.fire({
                      icon: "error",
                      title: "กรุณาระบุจำนวนสินค้า",
                      showConfirmButton: true,
                    });
                    this.isValid = false;
                  } else {
                    this.isValid = true;
                  }
                }
              });
              // นอก loop

              await this.validateForm();
              await this.checkFormIsEmpty();
              if (this.isValid) {
                let responseData = [];
                let body = {
                  to_warehouse_id: this.form.toWarehouseId,
                  products: this.dataItemsShow,
                  remark: this.form.remark,
                  status: "สำเร็จ",
                  companyId: localStorage.getItem("companyId"),
                };
                console.log("body", body);
                try {
                  responseData = await transferApi.transfer.addStockTransaction(
                    body
                  );
                } catch (error) {
                  console.log(error);
                }
                if (responseData.response_status === "SUCCESS") {
                  Swal.fire({
                    icon: "success",
                    title: "บันทึกข้อมูลสำเร็จ",
                    showConfirmButton: true,
                  }).then(() => {
                    this.$router.push("/sale/SA4-0");
                  });
                }
              }
            } else {
              Swal.fire({
                icon: "error",
                title: "กรุณาเลือกสินค้าอย่างน้อย 1 รายการ",
                showConfirmButton: true,
              });
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "กรุณาเลือกคลัง",
              showConfirmButton: true,
            });
          }
        }
      });
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    checkFormIsEmpty() {
      !this.form.toWarehouseId
        ? (this.isValid = false)
        : !this.dataItemsShow.length > 0
        ? (this.isValid = false)
        : (this.isValid = true);
    },

    async goToDelete(item, index) {
      Swal.fire({
        icon: "question",
        title: `${this.$t("want2delete")} ${item.code} ${this.$t("ornot")} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.confirmDelete(item, index);
        }
      });
    },
    async confirmDelete(item, index) {
      this.dataItemsShow.splice(index, 1);
    },
    cancel() {
      this.$router.push("/sale/SA4-0");
    },
    addItem() {
      this.dialogAddItem = true;
    },
    closeDialogAddItem() {
      this.dialogAddItem = false;
    },
  },
};
</script>
