<template>
  <div>
    <button
      type="button"
      class="btn btn-primary"
      id="button-open-dialog-search-product"
      data-bs-toggle="modal"
      data-bs-target="#search_modal_product"
      hidden
    ></button>
    <div
      class="modal fade"
      tabindex="-1"
      id="search_modal_product"
      data-bs-backdrop="static"
    >
      <!-- <div class="modal modal-fullscreen fade" tabindex="-1" id="kt_modal_2"></div> -->
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="d-flex justify-content-between">
            <h5 class="modal-title"></h5>
            <button
              data-bs-dismiss="modal"
              @click="close()"
              type="button"
              class="btn btn-sm"
            >
              <i
                class="bi bi-x m-0 p-0"
                id="close-btn-product"
                style="font-size: 25px"
              ></i>
            </button>
          </div>

          <div class="card-title row ms-6 me-5 mt-6">
            <div class="col-sm-3 mb-4">
              <label form-label>Code/Name</label>
              <input
                v-model="searchInput"
                :placeholder="$t(`search`)"
                type="text"
                class="form-control"
                @keypress.enter="search()"
              />
            </div>

            <div class="col-sm-3 mt-4 text-start" style="align-self: center">
              <button
                @click="clear()"
                type="button"
                class="btn btn-sm btn-light-danger me-2"
                style="font-size: 14px; font-weight: bold"
              >
                {{ $t("clear") }}
              </button>

              <button
                @click="search()"
                type="button"
                class="btn btn-sm btn-light-primary"
                style="font-size: 14px; font-weight: bold"
              >
                {{ $t("search") }}
              </button>
            </div>
          </div>

          <div class="card-body" style="padding-top: 3px">
            <div class="table-responsive">
              <a-table :dataSource="dataItemsShow" :columns="columns">
                <template #headerCell="{ column }">
                  <template v-if="column.dataIndex === 'no'">
                    {{ $t("no") }}
                  </template>
                  <template v-if="column.dataIndex === 'code'">
                    {{ $t("productcode") }}
                  </template>
                  <template v-if="column.dataIndex === 'name'">
                    {{ $t("productname") }}
                  </template>
                  <template v-if="column.dataIndex === 'productGroup'">
                    {{ $t("productgroupname") }}
                  </template>
                  <template v-if="column.dataIndex === 'cost'">
                    {{ $t("cost") }}
                  </template>
                  <template v-if="column.dataIndex === 'unit_price'">
                    {{ $t("sellprice") }}
                  </template>
                  <template v-if="column.dataIndex === 'status'">
                    {{ $t("status") }}
                  </template>
                </template>

                <template #bodyCell="{ column, record, index }">
                  <template v-if="column.dataIndex === 'no'">
                    {{ record.no }}
                  </template>
                  <template v-if="column.dataIndex === 'productGroup'">
                    <span v-if="record.productGroup">
                      {{ record.productGroup ? record.productGroup.code : "" }}
                      {{
                        record.productGroup
                          ? " | " + record.productGroup.name
                          : ""
                      }}
                    </span>
                  </template>
                  <template v-if="column.dataIndex === 'cost'">
                    <span v-if="record.productPrices.length != 0">
                      {{ record.productPrices[0].cost }}
                    </span>
                    <span v-else>-</span>
                  </template>
                  <template v-if="column.dataIndex === 'unit_price'">
                    <span v-if="record.productPrices.length != 0">
                      {{ record.productPrices[0].unit_price }}
                    </span>
                    <span v-else>-</span>
                  </template>
                  <template v-if="column.dataIndex === 'status'">
                    <span
                      v-if="record.status == 'ขายปกติ'"
                      style="color: green"
                    ></span>
                    <span v-else style="color: red">{{ record.status }}</span>
                  </template>
                  <template v-if="column.dataIndex === 'action'">
                    <button
                    type="button"
                      data-bs-dismiss="modal"
                      class="btn btn-light btn-primary"
                      @click="selectItem(record)"
                    >
                      เลือก
                    </button>
                  </template>
                </template>
              </a-table>
            </div>
          </div>

          <!-- <footer class="pb-10">
            <div class="row">
              <div class="col-6 ms-13">
                <button
                  @click="submit"
                  type="button"
                  class="btn btn-primary me-3"
                  data-bs-dismiss="modal"
                  :disabled="loadingUpdate"
                >
                  <div
                    v-if="loadingUpdate"
                    class="spinner-grow spinner-grow-sm text-light"
                    role="status"
                  ></div>
                  ตกลง
                </button>
                <button
                  @click="close"
                  type="button"
                  class="btn btn-light"
                  data-bs-dismiss="modal"
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </footer> -->
          <!-- <div class="modal-footer d-flex justify-content-start">
              <hr class="pt-0 mt-0" style="color: LightGrey" />
            </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { Decode, Encode } from "@/services";
import loginApi from "@/api/user";
import userApi from "@/api/user/";
import customerAPI from "@/api/customer/";
import whApi from "@/api/warehouse/";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  props: {
    dialogSearchProduct: Boolean,
    loadingUpdate: Boolean,
  },
  data: () => ({
    isSubmit: false,
    valid: false,
    showPassword: false,
    columns: [
      { title: "no", dataIndex: "no" },
      { title: "customercode", dataIndex: "code" },
      { title: "customername", dataIndex: "name" },
      // { title: "address", dataIndex: "address" },
      // { title: "branchs", dataIndex: "branch.name" },
      // { title: "storetype", dataIndex: "business_type" },
      // { title: "route", dataIndex: "route" },
      { title: "action", dataIndex: "action" },
    ],
    dataItemsShow: [],
    tableItems: [],
    allData: [],
    branches: [],
    store_types: [],
    routes: [],
    form: {
      storeTypeId: "",
      branchId: "",
      routeId: "",
    },
    searchInput: "",
  }),
  watch: {
    dialogSearchProduct(val) {
      if (val) {
        document.getElementById("button-open-dialog-search-product").click();
        // this.getAllUser();
        // this.getAll();
        // this.getAllBranch();
        // this.getAllStoreType();
        // this.getAllRoute();
      }
    },
  },
  methods: {
    selectItem(record) {
      // console.log("record", record);
      this.$emit("submitSearchProduct", record);
      document.getElementById("close-btn-product").click;
      this.$emit("closeDialogSearchProduct");
      // this.close();
      // this.clear();
    },
    async search() {
      // this.loading = true;

      // storeTypeId: this.form.storeTypeId,
      // branchId: this.form.branchId,
      // routeId: this.form.routeId,

      const body = {
        search: this.searchInput,
        warehouseId: localStorage.getItem("warehouseId"),
        companyId: localStorage.getItem("companyId"),
      };
      const responseSearch = await whApi.product.search(body);

      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.dataItemsShow.forEach((element, index) => {
          element.no = index + 1;
        });
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
      }
    },
    // async getAllBranch() {
    //   let responseData = [];
    //   try {
    //     responseData = await whApi.branch.getAll();
    //   } catch (error) {
    //     console.log(error);
    //   }
    //   if (responseData.response_status === "SUCCESS") {
    //     this.branches = responseData.data;
    //   }
    // },
    // async getAllStoreType() {
    //   let responseData = [];
    //   try {
    //     responseData = await customerAPI.store_type.getAll();
    //   } catch (error) {
    //     console.log(error);
    //   }
    //   if (responseData.response_status === "SUCCESS") {
    //     this.store_types = responseData.data;
    //   }
    // },
    // async getAllRoute() {
    //   let responseData = [];
    //   try {
    //     responseData = await customerAPI.route.getAll();
    //   } catch (error) {
    //     console.log(error);
    //   }
    //   if (responseData.response_status === "SUCCESS") {
    //     this.routes = responseData.data;
    //   }
    // },

    // async getAll(method) {
    //   this.loading = true;
    //   let responseData = [];
    //   try {
    //     responseData = await customerAPI.customer.getAll();
    //   } catch (error) {
    //     console.log(error);
    //   }
    //   if (responseData.response_status === "SUCCESS") {
    //     this.dataItemsShow = responseData.data;
    //     this.tableItems = responseData.data;
    //     this.allData = responseData.data;
    //     this.loading = false;
    //     if (method === 1) {
    //       await this.$router.push({
    //         query: {
    //           page: this.$route.query.page,
    //           get: 1,
    //         },
    //       });
    //     }
    //   } else {
    //     this.loading = false;
    //     Swal.fire({
    //       icon: "error",
    //       title: "เกิดข้อผิดพลาด",
    //       text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
    //     });
    //   }
    // },

    async submit() {
      let confirmPass = `${this.form.pass1}${this.form.pass2}${this.form.pass3}${this.form.pass4}${this.form.pass5}${this.form.pass6}`;
      let loginResponse = [];
      try {
        loginResponse = await loginApi.login.login({
          username: this.userName,
          password: confirmPass,
        });
      } catch (err) {
        console.log(err);
      }
      if (loginResponse.response_status === "SUCCESS") {
        this.clear();
        this.$emit("submitConfirmPass", true);
      } else {
        Swal.fire({
          icon: "error",
          title: "รหัสผ่านผิด !",
          html: `<div style="font-size:16px">Username : <b>${this.userName}</b> <br><br>รหัสผ่านไม่ถูกต้อง</div>`,
          confirmButtonText: "ตกลง",
        }).then(() => {
          this.close();
        });
      }
    },

    close() {
      this.$emit("closeDialogSearchProduct");
      this.clear();
    },
    clear() {
      this.searchInput = "";
    },
  },
};
</script>
  
  <style scoped>
@media only screen and (max-width: 90vw) {
  .display-phone {
    text-align: left !important;
  }
}
#close-btn-product:hover {
  background-color: LightGrey;
  border-radius: 25px;
  color: white;
}
</style>
  